<template>
  <div class='login'>
    <div class='uxg'>Log In</div>
    <div class='aqi'>
      New user?
      <router-link class='pyx'
        :to='{ name: "register" }'>
        Create an account
      </router-link>
      &nbsp;or&nbsp;
      <span class='pyx'
        @click='signInAsGuest'>
        Continue as guest
      </span>
    </div>
    <div class='log'>
      <input-element
        type='email'
        label='Working Email Address'
        v-model='inputs.email'
        @keyup.enter='signIn'/>
      <input-element class='fay'
        type='password'
        label='Password'
        v-model='inputs.password'
        @keyup.enter='signIn'/>
      <div class='fyo'>
        <router-link class='pyx'
          :to='{ name: "reset-password" }'>
          Forgot password?
        </router-link>
      </div>
    </div>
    <div class='mec'>
      <button-element
        :disabled='signingIn'
        @click='signIn'>
        Log In
        <progress-element class='mmr'
          :active='signingIn'/>
      </button-element>
    </div>
    <flash-message-component
      ref='errorDialog'
      type='error'>
      <template v-if='errorCode === "user-not-found"'>Couldn't find your account with that email.</template>
      <template v-if='errorCode === "wrong-password"'>Wrong password. Try again or click Forgot password to reset it.</template>
      <template v-if='errorCode === "user-disabled"'>Your account has been disabled. Please contact RISE for more information.</template>
      <template v-if='errorCode === "unknown"'>Oops! Something went wrong. Please try again later.</template>
    </flash-message-component>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import FlashMessage from '@/components/flash-message/flash-message.vue'
  import store from '@/store'
  import { GET_USER, SIGN_IN, SIGN_IN_AS_GUEST } from '@/store/action-types'

  export default {
    components: {
      'flash-message-component': FlashMessage
    },

    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch(GET_USER)

      if (user?.emailVerified) {
        next({ name: 'profile' })
        return
      }
      next()
    },

    setup () {
      let router = useRouter()
      let store = useStore()

      let signingIn = ref(false)
      let errorDialog = ref(null)
      let errorCode = ref('')
      let inputs = ref({
        email: '',
        password: ''
      })

      let signInAsGuest = async () => {
        let user = await store.dispatch(SIGN_IN_AS_GUEST)
        if (!user) {
          errorCode.value = 'unknown'
          errorDialog.value.show()
        }
        router.push({ name: 'start' })
      }

      let signIn = async () => {
        if (
          !inputs.value.email ||
          !inputs.value.password
        ) return

        try {
          signingIn.value = true
          await store.dispatch(SIGN_IN, inputs.value)
          router.push({ name: 'start' })
        } catch (error) {
          switch (error.code) {
            case 'auth/invalid-email':
            case 'auth/user-not-found':
              errorCode.value = 'user-not-found'
              break
            case 'auth/wrong-password':
              errorCode.value = 'wrong-password'
              break
            case 'auth/user-disabled':
              errorCode.value = 'user-disabled'
              break
            default:
              errorCode.value = 'unknown'
          }
          errorDialog.value.show()
        } finally {
          signingIn.value = false
        }
      }

      return {
        signingIn,
        signInAsGuest,
        errorDialog,
        errorCode,
        inputs,
        signIn
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .uxg {
    margin-top: 16px;
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
  }

  .aqi {
    margin-top: 24px;
    color: map.get(variables.$colors, 'gray', '700');
    font-size: map.get(variables.$font-sizes, 'sm');
  }

  .pyx {
    text-decoration: underline;
    cursor: pointer;
  }

  .log {
    margin-top: 16px;
    padding: 24px;
    background-color: white;
    border-radius: 4px;
  }

  .fay {
    margin-top: 24px;
  }

  .fyo {
    margin-top: 24px;
    color: map.get(variables.$colors, 'gray', '700');
    font-size: map.get(variables.$font-sizes, 'sm');
    text-align: right;
  }

  .mec {
    margin-top: 24px;
    text-align: center;
  }

  .mmr {
    margin-left: 16px;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .uxg {
      text-align: center;
    }

    .aqi {
      margin-top: 32px;
    }

    .log {
      padding: 32px;
    }

    .fay {
      margin-top: 32px;
    }

    .fyo {
      margin-top: 32px;
    }

    .mec {
      margin-top: 32px;
    }
  }
</style>
